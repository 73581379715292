.Sub-root {
	width: 100%;
	border-radius: 4px;
	color: white;
	min-height: 57.2px;
}
div.Sub-lesson {
	width: 100px;
	margin-left: 15px;
	text-align: center;
	display: inline-block;

}
span.Sub-lesson {
	font-size: 40px;
	height: 48.8px;
}
.Sub-changes {
	display: inline-block;
	width: calc(100% - 121px);
	margin-right: 6px;
}
